import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import Main from './components/pages/Main';
import HeaderAndFooterOnly from './components/layouts/HeaderAndFooterOnly';
import VerificationToken from './components/pages/VerificationToken';
import DashboardSidebar from './components/layouts/DashboardSidebar';
import DashboardSidebarExpanded from './components/layouts/DashboardSidebarExpanded';
import Dashboard from './components/pages/Dashboard';
import ProviderDashboard from './components/pages/ProviderDashboard';
import ProviderAccounts from './components/pages/ProviderAccounts';
import ProviderConnections from './components/pages/ProviderConnections';
import UserAccounts from './components/pages/UserAccounts';
import ContentManagement from './components/pages/ContentManagement';
import ManageStaff from './components/pages/ManageStaff';
import ChangeLog from './components/pages/ChangeLog';
import AccountSettings from './components/pages/AccountSettings';
import ChangePassword from './components/pages/ChangePassword';
import TermsAndConditionsPage from './components/pages/TermsAndConditionsPage';

import LoggedUserContextProvider from './contexts/LoggedUser';
import ProvidersContextProvider from './contexts/ProvidersContext';
import RegistrationContextProvider from './contexts/RegistrationContext';
import SocketContextProvider from './contexts/SocketContext';
import ForgotPasswordContextProvider from './contexts/ForgotPasswordContext';
import UserAccountDetails from './components/pages/UserAccountDetails';
import UserAccountPersonalInformation from './components/pages/UserAccountPersonalInformation';
import UserAccountSecurityQuestions from './components/pages/UserAccountSecurityQuestions';
import UserAccountPasswordRecovery from './components/pages/UserAccountPasswordRecovery';
import UserAccountConnectedProviders from './components/pages/UserAccountConnectedProviders';
import PasswordRecoveryAskEmail from './components/pages/passwordRecovery/PasswordRecoveryAskEmail';
import PasswordRecoveryToken from './components/pages/passwordRecovery/PasswordRecoveryToken';
import PasswordRecoveryResetPassword from './components/pages/passwordRecovery/PasswordRecoveryResetPassword';
import PasswordRecoveryConfirmation from './components/pages/passwordRecovery/PasswordRecoveryConfirmation';
import DashboardSidebarExpandedProvider from './components/layouts/DashboardSidebarExpandedProviders';
import DashboardSidebarAccount from './components/layouts/DashboardSidebarAccount';
import TermsAndAgreementsContextProvider from './contexts/TermsAndAgreementsContext';
import ErrorPage from './components/ErrorPage';
import ProviderInformation from './components/ProviderInformation';

function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

const App = () => {

  return (
    <TermsAndAgreementsContextProvider>
      <SocketContextProvider>
        <RegistrationContextProvider>
          <ForgotPasswordContextProvider>
            <LoggedUserContextProvider>
              <ProvidersContextProvider>
                <Router history={history}>
                  <div>
                    <Switch>
                      <RouteWrapper exact path="/" component={TermsAndConditionsPage} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/main" component={Main} layout={HeaderAndFooterOnly} />

                      {/* Forgot Password */}
                      <RouteWrapper exact path="/passwordRecoveryAskEmail" component={PasswordRecoveryAskEmail} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/passwordRecoveryToken" component={PasswordRecoveryToken} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/passwordRecoveryResetPassword" component={PasswordRecoveryResetPassword} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/passwordRecoveryToken" component={PasswordRecoveryToken} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/passwordRecoveryConfirmation" component={PasswordRecoveryConfirmation} layout={HeaderAndFooterOnly} />


                      <RouteWrapper exact path="/verificationToken" component={VerificationToken} layout={HeaderAndFooterOnly} />
                      <RouteWrapper exact path="/dashboard" component={Dashboard} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/providerInformation" component={ProviderInformation} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/providerAccounts" component={ProviderAccounts} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/providerDashboard" component={ProviderDashboard} layout={DashboardSidebarExpandedProvider} />

                      <RouteWrapper exact path="/providerConnections" component={ProviderConnections} layout={DashboardSidebarExpandedProvider} />
                      <RouteWrapper exact path="/userAccounts" component={UserAccounts} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/userAccountDetails" component={UserAccountDetails} layout={DashboardSidebarExpanded} />
                      <RouteWrapper exact path="/userAccountSecurityQuestions" component={UserAccountSecurityQuestions} layout={DashboardSidebarExpanded} />
                      <RouteWrapper exact path="/userAccountPasswordRecovery" component={UserAccountPasswordRecovery} layout={DashboardSidebarExpanded} />
                      <RouteWrapper exact path="/userAccountPersonalInformation" component={UserAccountPersonalInformation} layout={DashboardSidebarExpanded} />
                      <RouteWrapper exact path="/userAccountConnectedProviders" component={UserAccountConnectedProviders} layout={DashboardSidebarExpanded} />
                      <RouteWrapper exact path="/contentManagement" component={ContentManagement} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/manageStaff" component={ManageStaff} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/changeLog" component={ChangeLog} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/accountSettings" component={AccountSettings} layout={DashboardSidebar} />
                      <RouteWrapper exact path="/changePassword" component={ChangePassword} layout={DashboardSidebarAccount} />
                      <RouteWrapper path="*" component={ErrorPage} layout={HeaderAndFooterOnly} />

                    </Switch>
                  </div>
                </Router>
              </ProvidersContextProvider>
            </LoggedUserContextProvider>
          </ForgotPasswordContextProvider>
        </RegistrationContextProvider>
      </SocketContextProvider>
    </TermsAndAgreementsContextProvider>
  );
}

export default App;
