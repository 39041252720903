import React, { useContext } from 'react';
import { LoggedUserContext } from '../../contexts/LoggedUser';

const UserAccountPasswordRecovery = () => {

    const { currentSelectedUser } = useContext(LoggedUserContext);

    return (
        <div>
            {/* <!-- Main Content--> */}
            <div>

                <ul className="container-with-sidebar__breadcrumbs sticky">
                    <li><a href="user-accounts.html">User Accounts</a></li>
                    <li>{currentSelectedUser[0]} {currentSelectedUser[1]}</li>
                    <li className="breadcrumbs--title">
                        <h1>Password Recovery</h1>
                    </li>
                </ul>

                <section className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col">
                            <p className="text-center">
                                <img src="images/image-password-recovery.png"
                                    alt="Illustration depicting a lock and password" className="mt-1" />
                            </p>
                        </div>
                        <div className="col-grow-3">
                            <p className="p-primary">A token will be sent to the user email: <strong>myemail@email.com</strong> with password reset instructions. </p>
                            <form className="mt-3">
                                <div className="form-group mt-3">
                                    <button type="submit" className="btn btn-primary">Send password recovery link</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default UserAccountPasswordRecovery;