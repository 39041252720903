import React, { useEffect, useState, useContext } from 'react';
import graphQL from '../../apis/graphQL';
import ProviderConnectionsTable from './ProviderConnectionsTable';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import AuthContextProvider from '../../contexts/AuthContext';

const ProviderConnections = () => {

    const { currentSelectedNPIFromProvidersList, currentSelectedNpiFirstNameProvidersList, currentSelectedNpiLastNameProvidersList, changeOnProvidersConnections } = useContext(LoggedUserContext);

    // REACT TABLES
    const columns = React.useMemo(
        () => [
            {
                Header: 'USER',
                accessor: 'user',
            },
            {
                Header: 'ROLE',
                accessor: 'role',
            },
            {
                Header: 'STATUS',
                accessor: 'status',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
            },
        ],
        []
    )

    // .REACT TABLES

    // providersData
    const [providersData, setProvidersData] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await graphQL(`query{
            adminProvidersByNPI(npi:${parseInt(currentSelectedNPIFromProvidersList)}){
                _id
                firstName
                lastName
                email
                status
                role
            }
            }`);
            if (response.adminProvidersByNPI) {
                console.log(response.adminProvidersByNPI);
                let dataArray = []
                response.adminProvidersByNPI.forEach((provider) => {
                    dataArray.push({
                        user: [provider._id, provider.firstName, provider.lastName, provider.email],
                        role: provider.role,
                        status: provider.status,
                        actions: provider._id
                    })
                });
                setProvidersData(dataArray);
            } else if (response) {
            }
        })()
    }, [changeOnProvidersConnections])

    return (
        // < !--Main Content-- >
        <AuthContextProvider>
            <div>

                <ul className="container-with-sidebar__breadcrumbs sticky">
                    <li><a href="provider-accounts.html">Providers</a></li>
                    <li>{currentSelectedNpiFirstNameProvidersList} {currentSelectedNpiLastNameProvidersList}</li>
                    <li className="breadcrumbs--title"><h1>Provider´s connections <small className="isnumber">({providersData.length})</small></h1></li>
                </ul>

                <section className="container-with-sidebar__content-block">
                    <div className="panel panel-data mt-3">
                        <div className="panel-body">
                            <div className="table-scroll">
                                <ProviderConnectionsTable columns={columns} data={providersData} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </AuthContextProvider>
    );
}

export default ProviderConnections;