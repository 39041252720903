import React, { createContext, useState, useEffect } from 'react';

export const LoggedUserContext = createContext();

const LoggedUserContextProvider = (props) => {
    // currentSelectedNPIFromProvidersList
    const [currentSelectedNPIFromProvidersList, setCurrentSelectedNPIFromProvidersList] = useState("");
    // currentSelectedNpiFirstNameProvidersList
    const [currentSelectedNpiFirstNameProvidersList, setCurrentSelectedNpiFirstNameProvidersList] = useState("");
    // currentSelectedNpiLastNameProvidersList
    const [currentSelectedNpiLastNameProvidersList, setCurrentSelectedNpiLastNameProvidersList] = useState("");
    // changeOnProvidersConnections
    const [changeOnProvidersConnections, setChangeOnProvidersConnections] = useState(0);
    //email
    const [email, setEmail] = useState('');
    // profile
    const [profile, setProfile] = useState('');
    // firstTimeLogin
    const [firstTimeLogin, setFirstTimeLogin] = useState('');
    // connectedProviders
    const [connectedProviders, setConnectedProviders] = useState([]);
    // connectedUsers
    const [connectedUsers, setConnectedUsers] = useState([]);
    // firstName
    const [firstName, setFirstName] = useState('');
    // lastName
    const [lastName, setLastName] = useState('');
    // isPMG
    const [isPMG, setIsPMG] = useState(false);
    // currentSelectedUser
    const [currentSelectedUser, setCurrentSelectedUser] = useState('');
    // scrollingClass
    const [scrollingClass, setScrollingClass] = useState('container-with-sidebar__title');

    useEffect(() => {
        window.onscroll = function () {
            if (window.pageYOffset > 500) {
                setScrollingClass('container-with-sidebar__title sticky');
            } else {
                setScrollingClass('container-with-sidebar__title');
            }
        };
    }, []);

    console.log('connectedProviders');
    console.log(connectedProviders);

    return (
        <LoggedUserContext.Provider value={{ email, setEmail, profile, setProfile, firstTimeLogin, setFirstTimeLogin, connectedProviders, setConnectedProviders, connectedUsers, setConnectedUsers, firstName, setFirstName, lastName, setLastName, isPMG, setIsPMG, currentSelectedUser, setCurrentSelectedUser, currentSelectedNPIFromProvidersList, setCurrentSelectedNPIFromProvidersList, currentSelectedNpiFirstNameProvidersList, setCurrentSelectedNpiFirstNameProvidersList, currentSelectedNpiLastNameProvidersList, setCurrentSelectedNpiLastNameProvidersList, changeOnProvidersConnections, setChangeOnProvidersConnections, scrollingClass, setScrollingClass }}>
            {props.children}
        </LoggedUserContext.Provider>
    );
}

export default LoggedUserContextProvider;