import React, { useState, useContext, useEffect } from 'react';
import graphQL from '../../apis/graphQL';
import { LoggedUserContext } from '../../contexts/LoggedUser';

const SuspendDeleteAccountModal = (props) => {
    console.log(props);
    const { currentSelectedUser } = useContext(LoggedUserContext)
    // message
    const [message, setMessage] = useState('');
    // additionalMessage
    const [additionalMessage, setAdditionalMessage] = useState('');
    // disabled
    const [disabled, setDisabled] = useState(true);

    const continueButton = async () => {
        const response = await graphQL(`mutation{
            adminUserAccountSuspend(id: "${currentSelectedUser[5]}", action: "${props.action}", reason: "${message}", additionalReason: "${additionalMessage}"){
                success
                message
            }
            }`);

        if (response.adminUserAccountSuspend) {
            props.changeStatus()
            props.showOrHideModal()
        } else if (response) {

        }
    }

    useEffect(() => {
        if (message === '') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [message]);

    return (
        // <!-- Modal Suspend Reasons -->
        <div id="modal-suspend_reasons" className="overlay">
            <div className="modal">
                <h1>Suspend/Delete Account</h1>
                <a className="close" href="#"><i className="fas fa-times-circle"></i></a>
                <div className="content-forms">
                    <strong>Please select the reason for suspending / deleting this account</strong>

                    <div className="form-check mt-2">
                        <input className="form-check-input" type="radio" name="suspenditem" id="suspenditem1" value="suspenditem1" onChange={() => setMessage('Suspendisse sollicitudin lectus sed ipsum molestie scelerisque.')} />
                        <label className="form-check-label" htmlFor="suspenditem1">
                            Suspendisse sollicitudin lectus sed ipsum molestie scelerisque.
                    </label>
                    </div>

                    <div className="form-check mt-1">
                        <input className="form-check-input" type="radio" name="suspenditem" id="suspenditem2" value="suspenditem2" onChange={() => setMessage('Phasellus congue nunc quis tellus faucibus fermentum.')} />
                        <label className="form-check-label" htmlFor="suspenditem2">
                            Phasellus congue nunc quis tellus faucibus fermentum.
                </label>
                    </div>

                    <div className="form-check mt-1">
                        <input className="form-check-input" type="radio" name="suspenditem" id="suspenditem3" value="suspenditem3" onChange={() => setMessage('Vivamus posuere risus sit amet sapien tempus suscipit.')} />
                        <label className="form-check-label" htmlFor="suspenditem3">
                            Vivamus posuere risus sit amet sapien tempus suscipit.
                </label>
                    </div>

                    <div className="form-check mt-1">
                        <input className="form-check-input" type="radio" name="suspenditem" id="suspenditem4" value="suspenditem4" onChange={() => setMessage('Others')} />
                        <label className="form-check-label" htmlFor="suspenditem4">
                            Others
                </label>
                        <p><textarea className="form-control input-wfull" id="Textarea1" placeholder="" onChange={(e) => { setAdditionalMessage(e.target.value) }}></textarea></p>
                    </div>

                    <div className="form-group mt-3">
                        <a onClick={() => props.showOrHideModal()} className="btn btn-secondary mr-2">Cancel</a>
                        <button disabled={disabled} className="btn btn-primary mr-2" onClick={continueButton}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SuspendDeleteAccountModal;