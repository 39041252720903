import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../history';
import graphQL from '../../../apis/graphQL';
import { ForgotPasswordContext } from '../../../contexts/ForgotPasswordContext';
import Input from '../../reusable/Input';

const PasswordRecoveryToken = () => {

    const { maskedEmail, email, token, setToken } = useContext(ForgotPasswordContext);
    // loading
    const [loading, setLoading] = useState(true);
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState(false);

    useEffect(() => {
        if (email === '') {
            history.push('/passwordRecoveryAskEmail')
        }
    })

    useEffect(() => {
        if (email === '') {
            history.push('/passwordRecoveryAskEmail');
        } else {
            (async () => {
                setLoading(true);
                const response = await graphQL(`mutation{
                    adminSendPasswordRecoveryToken(email: "${email}"){
                        success
                        message
                    }
                    }`);
                setLoading(false);
                if (response.adminSendPasswordRecoveryToken) {

                } else if (response) {
                    setLoading(false);
                }
            })()
        }
    }, [email])

    const continueButtonClicked = async () => {
        setLoading(true);
        const response = await graphQL(`mutation{
            adminVerifyPasswordRecoveryToken(email: "${email}", token: "${token}"){
                success
                message
            }
            }`);
        setLoading(false);
        if (response.adminVerifyPasswordRecoveryToken) {
            history.push('/passwordRecoveryResetPassword');
        } else if (response) {
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    const onChange = (e) => {
        setGraphQLErrors(false);
        setToken(e.target.value);
    }

    return (
        <div className="login-wrapper mt-1">
            <div className="container">
                {/* <!-- Two Factor--> */}
                <div className="panel-clear">
                    <div className="row gap-triple">
                        <div className="col">
                            <h2>Password Recovery</h2>
                            <p className="text-center">
                                <img src="images/image-password-recovery.png"
                                    alt="Illustration depicting a lock and password" className="mt-1" />
                            </p>
                        </div>
                        <div className="col-grow-2">
                            <p className="p-primary">
                                A message with a verification code has been sent to your email
                                <span
                                    className="text-spread text-bold"
                                > {maskedEmail} </span>
                            </p>
                            <p className="text-bold mt-2">Enter the code to continue:</p>
                            <form className="mt-3">
                                {/* <!-- <div className="message message-error">
                                    Verification code is not valid.
                                </div> --> */}

                                {/* verificationCode field*/}
                                <Input
                                    type="text"
                                    label="Verification Code:"
                                    id="verificationCode"
                                    placeholder=""
                                    onChange={onChange}
                                    errorMessage={graphQLErrors}
                                />
                                {/* .verificationCode field */}

                                <div className="form-group mt-3">
                                    <Link to="/main" className="btn btn-secondary mr-2">Cancel</Link>
                                    <button type="button"
                                        className="btn btn-primary"
                                        onClick={continueButtonClicked}
                                    >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Continue'}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordRecoveryToken;