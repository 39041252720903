import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCog, faChartBar, faHospital, faSearch, faUser, faEdit, faClock, faCogs, faChevronRight, faLock, faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import DashboardFooter from './DashboardFooter';
import { Link, useLocation } from 'react-router-dom';
import UserDropDown from '../reusable/UserDropDown';
import { Location } from 'react-router-dom'

const DashboardSidebar = (props) => {

    // initialSidebarClass
    const [initialSidebarClass, setInitialSidebarClass] = useState('container-with-sidebar__sidebar--full');
    // initialContentClass
    const [initialContentClass, setInitialContentClass] = useState('container-with-sidebar__content js-toggle-content');
    // iconOnlyInitialSidebarClass
    const [iconOnlyInitialSidebarClass, setIconOnlyInitialSidebarClass] = useState('main-menu-vertical__item-list');

    const toggleVisibility = () => {
        if (initialSidebarClass === 'container-with-sidebar__sidebar--full') {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list main-menu-vertical__item-list--icon-only');
            setInitialSidebarClass('container-with-sidebar__sidebar--narrow');
        } else {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list');
            setInitialSidebarClass('container-with-sidebar__sidebar--full');
        }

        if (initialContentClass === 'container-with-sidebar__content js-toggle-content') {
            setInitialContentClass('container-with-sidebar__content container-with-sidebar__content--wide js-toggle-content')
        } else {
            setInitialContentClass('container-with-sidebar__content js-toggle-content')
        }


    }

    const location = useLocation()
    return (
        <div>
            <div className="container--fixed-top">
                <header className="main main--slim">
                    <div className="header-left header-col">
                        <button type="button" onClick={toggleVisibility} className="hamburguer-button js-toggle-sidebar-btn "><FontAwesomeIcon icon={faBars} /></button>
                        <span className="ml-1 mr-1"><img className="logo-header" src="images/logo-psc.svg" alt="PSC" /></span><span className="tag">admin area</span>
                    </div>
                    <div className="header-full-col header-col text-right">
                        <UserDropDown />
                    </div>

                </header>
            </div>

            <div className="container-with-sidebar has-container--fixed-top">
                {/* <!-- Sidebar for menu--> */}
                <div className={`container-with-sidebar__sidebar js-toggle-sidebar ${initialSidebarClass}`}>
                    {/* <!-- Menu Principal --> */}
                    <nav className="main-menu-vertical">
                        <ul className={`${iconOnlyInitialSidebarClass} js-toggle-label`}>
                            <li className={location.pathname === '/dashboard' ? 'current' : ''} ><Link to="/dashboard"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faChartBar} /></span><span className="main-menu-vertical__label">Dashboard</span></Link></li>

                            <li className={location.pathname === '/providerAccounts' ? 'current' : ''} ><Link to="/providerAccounts"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faHospital} /></span><span className="main-menu-vertical__label">Providers</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faSearch} /></span></Link>
                            </li>

                            <li className={location.pathname === '/userAccounts' ? 'current' : ''} ><Link to="/userAccounts"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUsers} /></span><span className="main-menu-vertical__label">User Accounts</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faSearch} /></span></Link>
                            </li>

                            <li className={location.pathname === '/contentManagement' ? 'current' : ''} ><Link to="/contentManagement"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faEdit} /></span><span className="main-menu-vertical__label">Content Management</span></Link>
                            </li>

                            <li className={location.pathname === '/manageStaff' ? 'current' : ''} ><Link to="/manageStaff"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUsersCog} /></span><span className="main-menu-vertical__label">Manage Staff</span></Link>
                            </li>

                            <li className={location.pathname === '/changeLog' ? 'current' : ''} ><Link to="/changeLog"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faClock} /></span><span className="main-menu-vertical__label">Change Log</span></Link>
                            </li>

                            <li className={location.pathname === '/changePassword' ? 'current' : ''} ><Link to="/changePassword"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUserCog} /></span><span className="main-menu-vertical__label">Account Settings</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faChevronRight} /></span></Link>

                            </li>
                        </ul>
                    </nav>
                    {/* <!-- End Menu Principal --> */}
                </div>
                {/* <!-- End Sidebar for menu--> */}
                <div className={initialContentClass}>
                    {props.children}
                    <DashboardFooter />
                </div>
            </div>
        </div>
    );
}

export default DashboardSidebar;