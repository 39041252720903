import React, { useState, useEffect, useContext } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilter, faTable, faStepBackward, faChevronLeft, faChevronRight, faStepForward, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import history from '../../history';


const ChangeLogTable = (props) => {
    const { columns, data } = props;
    console.log('renderingAgain')

    // disableGetMoreData
    const [disableGetMoreData, setDisableGetMoreData] = useState(false);
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: props.pageIndex, pageSize: 10 },
        },
        useSortBy,
        usePagination
    )

    const { setCurrentSelectedUser } = useContext(LoggedUserContext);

    const goToAccountDetails = (selectedUser) => {
        setCurrentSelectedUser(selectedUser)
        history.push('/userAccountDetails');
    }

    if (pageIndex === pageOptions.length - 1 && !disableGetMoreData) {
        console.log('gettingMoreData');
        console.log(pageIndex);
        setDisableGetMoreData(true);
        props.getPaginationData(pageIndex);
    }
    // Render the UI for your table
    return (
        <>
            <table className="providers-list table--has-pagination" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key="hea" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}<span className="table--sort-element"><i className="fas fa-arrow-down"></i>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FontAwesomeIcon icon={faArrowDown} />
                                            : <FontAwesomeIcon icon={faArrowUp} />
                                        : ''}
                                </span></th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr key={i} className="clickable-row" {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    if (index === 1) {
                                        // console.log(cell.value);
                                        return (<td key={index}  {...cell.getCellProps()}>
                                            <div className="user-info">
                                                <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{cell.value[0]} {cell.value[1]}</strong><br />
                                                {cell.value[2]}
                                            </div>
                                        </td>
                                        )
                                    } else if (index === 3) {
                                        return (
                                            <td key={index}>
                                                <div className="user-info">
                                                    <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{cell.value[0]} {cell.value[1]}</strong><br />
                                                    {cell.value[2]}
                                                </div>
                                            </td>
                                        )

                                    } else {
                                        return (<td key={index} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>)
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            <div className="pagination">
                <span className="pagination__controls">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faStepBackward} />
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faStepForward} />
                    </button>
                </span>
                <span> Go to page: &nbsp;
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: "80px", marginLeft: "5px", marginRight: "10px" }}
                    />
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length} ({data.length})
                    </strong>{' '}
                </span>
                <span>

                </span>{' '}

            </div>
        </>
    )
}


export default ChangeLogTable;

