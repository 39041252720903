import React, { useContext } from 'react';
import { LoggedUserContext } from '../../contexts/LoggedUser';

const UserAccountSecurityQuestions = () => {

    const { currentSelectedUser } = useContext(LoggedUserContext)

    return (
        <div>
            {/* <!-- Main Content--> */}
            <div>
                <ul className="container-with-sidebar__breadcrumbs sticky">
                    <li><a href="user-accounts.html">User Accounts</a></li>
                    <li>{currentSelectedUser[0]} {currentSelectedUser[1]}</li>
                    <li className="breadcrumbs--title">
                        <h1>Security Questions</h1>
                    </li>
                </ul>

                <section className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col instructions">
                            <p><img src="images/ilus-security-questions.png"
                                alt="Ilustration Security Questions" className="mb-2 mt-2" />
                            </p>
                        </div>
                        <div className="col-grow-3">
                            <form>
                                <p className="mb-2">
                                    Security questions will be cleared out and user can set them again after login in
						</p>
                                {/* <!-- Action Buttons --> */}
                                <div className="form-group mt-3">
                                    <button type="submit" className="btn btn-primary">Reset Security Questions</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default UserAccountSecurityQuestions;