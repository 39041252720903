import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import AuthContextProvider from '../../contexts/AuthContext';
import graphQL from '../../apis/graphQL';
import Input from '../reusable/Input';

const ChangePassword = () => {

    const { firstName, lastName } = useContext(LoggedUserContext);
    // disabledContinue
    const [disabledContinue, setDisabledContinue] = useState(true);
    // formState
    const [formState, setFormState] = useState({
        values: {
            newPassword: '',
            currentPassword: '',
            confirmNewPassword: ''
        }
    });
    // loading
    const [loading, setLoading] = useState(false);


    //Check to disabled continue button
    useEffect(() => {
        if (passwordError) {
            setDisabledContinue(true);
        } else if (formState.values.currentPassword === '' || formState.values.newPassword === '' || formState.values.confirmNewPassword === '') {
            setDisabledContinue(true);
        } else if (formState.values.newPassword !== formState.values.confirmNewPassword) {
            setDisabledContinue(true);
        } else {
            setDisabledContinue(false);
        }
    }, [formState]);

    // passwordError
    const [passwordError, setPasswordError] = useState(false);

    const passwordRegexValidation = () => {
        let errorMessage = [];
        //Contains a number validation
        var regex = /[0-9]/g;
        if (regex.exec(formState.values.newPassword) == null) {
            errorMessage.push("Password must contain a number");
        }
        //length validation needs 8 characters mininum
        if (formState.values.newPassword.length < 8) {
            errorMessage.push("Password must at least 8 characters long");
        }

        //Contains at least one special character
        regex = /^[a-zA-Z0-9 ]*$/;
        if (regex.exec(formState.values.newPassword) != null) {
            errorMessage.push("Password must contain at least a special character");
        }
        if (errorMessage.length === 0) {
            setPasswordError(false);
        } else {
            setPasswordError(errorMessage.map((error, index) => {
                return <p key={index}>{error}</p>
            }));
        }
    }

    //Every time that password field changes, run the regex verification; if password isn't empty
    useEffect(() => {
        if (formState.values.newPassword !== '') {
            passwordRegexValidation();
        } else {
            setPasswordError(false);
        }
    }, [formState.values]);

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    const changePasswordAction = async () => {
        setLoading(true);
        const response = await graphQL(`
        mutation{
            adminAccountChangePassword(currentPassword: "${formState.values.currentPassword}", newPassword: "${formState.values.newPassword}", confirmNewPassword: "${formState.values.confirmNewPassword}"){
                success
                message
            }
        }`);
        setLoading(false);
        if (response.adminAccountChangePassword) {
            console.log(response.adminAccountChangePassword);
        } else if (response) {
            setLoading(false);
            //setGraphQLErrors(response);
        }
    }

    return (
        <AuthContextProvider>
            <div >
                <ul className="container-with-sidebar__breadcrumbs sticky">
                    <li><a href="user-accounts.html">Account Settings</a></li>
                    <li>{firstName} {lastName}</li>
                    <li className="breadcrumbs--title">
                        <h1>Change Password</h1>
                    </li>
                </ul>

                <section className="container-with-sidebar__content-block">
                    <div className="row indented gap-triple">
                        <div className="col">
                            <p className="text-center">
                                <img src="images/image-password-recovery.png"
                                    alt="Illustration depicting a lock and password" className="mt-1" />
                            </p>
                        </div>
                        <div className="col-grow-3">
                            <p className="p-primary text-bold">Please enter a new password and the new
                            password again for
						verification:</p>
                            <div className="mt-3">
                                {/* currentPassword field*/}
                                <Input
                                    type="password"
                                    label="Current Password:"
                                    id="currentPassword"
                                    placeholder=""
                                    onChange={onChange}
                                />
                                {/* .currentPassword field */}

                                {/* newPassword field*/}
                                <Input
                                    type="password"
                                    label="New Password:"
                                    id="newPassword"
                                    placeholder=""
                                    onChange={onChange}
                                    errorMessage={passwordError}
                                />
                                {/* .newPassword field */}

                                {/* confirmNewPassword field*/}
                                <Input
                                    type="password"
                                    label="Confirm New Password:"
                                    id="confirmNewPassword"
                                    placeholder=""
                                    onChange={onChange}
                                />
                                {/* .confirmNewPassword field */}

                                <div className="form-group mt-3">
                                    <a href="/changePassword" className="btn btn-secondary mr-2">Cancel</a>
                                    <button className="btn btn-primary" disabled={disabledContinue} onClick={changePasswordAction}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </AuthContextProvider>
    );
}

export default ChangePassword;