import React from 'react';
import TrelloWindow from '../reusable/TrelloWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <>
            <footer className="main container-with-sidebar__content-block footer--w-top-margin">
                <p><span className="phone">1 (800) 981 2737</span>
                    <span className="rrss">
                        <a href="mailto:info@asespr.org">
                            <span className="fa-layers fa-fw margin-adjust" style={{
                                marginRight: '22px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginTop: '-13px'
                            }}>
                                <FontAwesomeIcon icon={faCircle} size="2x" />
                                <FontAwesomeIcon icon={faEnvelope} className="fa-inverse"
                                    style={{ marginLeft: '8px' }} />
                            </span>
                        </a>
                        <a href="https://www.facebook.com/asespr/" rel="noopener noreferrer" target="_blank" >
                            <FontAwesomeIcon icon={faFacebook} size="2x" className="margin-adjust" />
                        </a>
                        <a href="https://twitter.com/ASESPUERTORICO" rel="noopener noreferrer" target="_blank">
                            <FontAwesomeIcon icon={faTwitter} size="2x" className="margin-adjust" />
                        </a>
                    </span>
                </p>
                <p>TTY/TDD disponible sólo para audio impedido: <a className="phone-number" href="tel:787-474-3389">(787) 474 3389</a></p>
            </footer>
            <TrelloWindow />
        </>
    );
}

export default Footer;