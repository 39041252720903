import React, { createContext, useEffect, useContext } from 'react';
import graphQL from '../apis/graphQL';
import { LoggedUserContext } from '../contexts/LoggedUser';
import history from '../history';
import { ProvidersContext } from '../contexts/ProvidersContext';
//import socketIOClient from "socket.io-client";



export const AuthContext = createContext();

const AuthContextProvider = (props) => {

    const { setEmail, setFirstName, setLastName } = useContext(LoggedUserContext);
    useEffect(() => {
        (async () => {
            const response = await graphQL(`query{
                adminVerifyAuthentication{
                    email
                    verificationToken
                    firstName
                    lastName
                }
            }`);
            if (response.adminVerifyAuthentication) {
                if (response.adminVerifyAuthentication.verificationToken !== null && response.adminVerifyAuthentication.verificationToken !== '') {
                    history.push('/verificationToken');
                }
                const { email, firstName, lastName } = response.adminVerifyAuthentication;
                setEmail(email);
                setFirstName(firstName);
                setLastName(lastName);
            } else {
                history.push('/');
            }
        })()
    }, []);

    return (
        <AuthContext.Provider >
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;