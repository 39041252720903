import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserCog, faChartBar, faHospital, faSearch, faUser, faEdit, faClock, faCogs, faChevronRight, faLock, faUserCheck, faAddressCard, faShieldAlt, faUsers, faUsersCog } from '@fortawesome/free-solid-svg-icons';
import DashboardFooter from './DashboardFooter';
import { Link } from 'react-router-dom';
import UserDropDown from '../reusable/UserDropDown';
import { useLocation } from 'react-router-dom';
import AuthContextProvider from '../../contexts/AuthContext';
import { LoggedUserContext } from '../../contexts/LoggedUser';

const DashboardSidebar = (props) => {

    const location = useLocation();

    const { currentSelectedUser } = useContext(LoggedUserContext);

    // initialSidebarClass
    const [initialSidebarClass, setInitialSidebarClass] = useState('container-with-sidebar__sidebar--full');
    // initialContentClass
    const [initialContentClass, setInitialContentClass] = useState('container-with-sidebar__content js-toggle-content');
    // iconOnlyInitialSidebarClass
    const [iconOnlyInitialSidebarClass, setIconOnlyInitialSidebarClass] = useState('main-menu-vertical__item-list');

    const toggleVisibility = () => {
        if (initialSidebarClass === 'container-with-sidebar__sidebar--full') {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list main-menu-vertical__item-list--icon-only');
            setInitialSidebarClass('container-with-sidebar__sidebar--narrow');
        } else {
            setIconOnlyInitialSidebarClass('main-menu-vertical__item-list');
            setInitialSidebarClass('container-with-sidebar__sidebar--full');
        }

        if (initialContentClass === 'container-with-sidebar__content js-toggle-content') {
            setInitialContentClass('container-with-sidebar__content container-with-sidebar__content--wide js-toggle-content')
        } else {
            setInitialContentClass('container-with-sidebar__content js-toggle-content')
        }


    }
    return (
        <AuthContextProvider>
            <div>
                <div className="container--fixed-top">
                    <header className="main main--slim">
                        <div className="header-left header-col">
                            <button type="button" onClick={toggleVisibility} className="hamburguer-button js-toggle-sidebar-btn "><FontAwesomeIcon icon={faBars} /></button>
                            <span className="ml-1 mr-1"><img className="logo-header" src="images/logo-psc.svg" alt="PSC" /></span><span className="tag">admin area</span>
                        </div>
                        <div className="header-full-col header-col text-right">
                            <UserDropDown />


                        </div>

                    </header>
                </div>

                <div className="container-with-sidebar has-container--fixed-top">
                    {/* <!-- Sidebar for menu--> */}
                    <div className={`container-with-sidebar__sidebar js-toggle-sidebar ${initialSidebarClass}`}>
                        {/* <!-- Menu Principal --> */}
                        <nav className="main-menu-vertical">
                            <ul className={`${iconOnlyInitialSidebarClass} js-toggle-label`}>
                                <li><Link to="/dashboard"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faChartBar} /></span><span className="main-menu-vertical__label">Dashboard</span></Link></li>

                                <li><Link to="/providerAccounts"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faHospital} /></span><span className="main-menu-vertical__label">Providers</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faSearch} /></span></Link>
                                </li>

                                <li ><Link to="/userAccounts"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUsers} /></span><span className="main-menu-vertical__label">User Accounts</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faSearch} /></span></Link>

                                    <ul className="main-menu-vertical__sub-menu sub-menu--visible-screen-size">
                                        <li className="main-menu-vertical__sub-menu--level1">{currentSelectedUser[0]} {currentSelectedUser[1]}</li>
                                        <li className="submenu--back"> <a href="user-accounts.html"> <i className="sub-menu__ico fas fa-chevron-left"></i>BACK</a></li>

                                        <li className={location.pathname === '/userAccountDetails' ? "current" : ""}><Link to="/userAccountDetails"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUserCheck} /></span><span className="main-menu-vertical__label">Status</span></Link></li>

                                        <li className={location.pathname === '/userAccountPersonalInformation' ? "current" : ""}><Link to="/userAccountPersonalInformation"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faAddressCard} /></span><span className="main-menu-vertical__label">Personal Info</span></Link></li>

                                        <li className={location.pathname === '/userAccountSecurityQuestions' ? "current" : ""}><Link to="/userAccountSecurityQuestions"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faShieldAlt} /></span><span className="main-menu-vertical__label">Security Questions</span></Link></li>

                                        <li className={location.pathname === '/userAccountPasswordRecovery' ? "current" : ""}><Link to="/userAccountPasswordRecovery"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faLock} /></span><span className="main-menu-vertical__label">Password</span></Link></li>

                                        <li className={location.pathname === '/userAccountConnectedProviders' ? "current" : ""}><Link to="/userAccountConnectedProviders"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faHospital} /></span><span className="main-menu-vertical__label">Connected Providers</span></Link></li>

                                    </ul>



                                </li>

                                <li><Link to="/contentManagement"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faEdit} /></span><span className="main-menu-vertical__label">Content Management</span></Link>
                                </li>

                                <li><Link to="/manageStaff"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUsersCog} /></span><span className="main-menu-vertical__label">Manage Staff</span></Link>
                                </li>

                                <li><Link to="/changeLog"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faClock} /></span><span className="main-menu-vertical__label">Change Log</span></Link>
                                </li>

                                <li><Link to="/changePassword"><span className="main-menu-vertical__ico"><FontAwesomeIcon icon={faUserCog} /></span><span className="main-menu-vertical__label">Account Settings</span><span className="main-menu-vertical__ico--right"><FontAwesomeIcon icon={faChevronRight} /></span></Link>

                                </li>
                            </ul>
                        </nav>
                        {/* <!-- End Menu Principal --> */}
                    </div>
                    {/* <!-- End Sidebar for menu--> */}
                    <div className={initialContentClass}>
                        {props.children}
                        <DashboardFooter />
                    </div>
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default DashboardSidebar;