import React from 'react';

const WarningAccountModal = (props) => {

    console.log(props);
    return (
        <div>
            {/* <!-- Ventana modal para el botón de Suspender cuenta --> */}
            < div id="modal-suspend" className="overlay" >
                <div className="modal">
                    <h1>Suspend/Delete Account</h1>
                    <a className="close" href="#"><i className="fas fa-times-circle"></i></a>
                    <div className="content">
                        <p><img className="mb-2" src="images/image-attention.png" /></p>
                        <strong>This user is connected with {props.connectedProviders.length} providers.</strong> <br /> Deleting or suspending this account will break the connections.

                    <div className="form-group mt-3">
                            <a onClick={() => props.showOrHideModal()} className="btn btn-secondary mr-2">Cancel</a>
                            <a onClick={() => props.continueToSuspendDeleteModal()} className="btn btn-primary mr-2">Continue</a>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
}

export default WarningAccountModal;