import React, { useState, useEffect } from 'react';
import InputWithoutDiv from '../reusable/InputWithoutDiv';
import graphQL from '../../apis/graphQL';
import AuthContextProvider from '../../contexts/AuthContext';

const ManageStaff = () => {

    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(true);
    // loading
    const [loading, setLoading] = useState(false);
    // graphQL
    const [graphQLErrors, setGraphQLErrors] = useState(false);
    // formState
    const [formState, setFormState] = useState({
        values: {}
    });
    // staff
    const [staff, setStaff] = useState([]);
    // updateCounter
    const [updateCounter, setUpdateCounter] = useState(0);
    // disabled
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {

        if (formState.values.email !== '' && formState.values.firstName !== '' && formState.values.lastName !== '' && formState.values.email !== undefined && formState.values.firstName !== undefined && formState.values.lastName !== undefined) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }

    }, [formState.values]);

    useEffect(() => {
        (async () => {
            setLoadingScreen(true);
            setLoading(true);
            const response = await graphQL(`query{
            adminGetUsers{
                _id
                email
                firstName
                lastName
                status
                lastLogin
            }
            }`);
            setLoading(false);
            if (response.adminGetUsers) {
                setLoadingScreen(false);
                console.log(response.adminGetUsers);
                setStaff(response.adminGetUsers);
            } else if (response) {
                setLoadingScreen(false);
                setLoading(false);
                setGraphQLErrors(response);
            }
        })()
    }, [updateCounter]);

    const createAdminUser = async (e) => {
        e.preventDefault();
        console.log(formState.values);
        setLoading(true);
        const response = await graphQL(`mutation{
        adminRegister(email: "${formState.values.email}", firstName: "${formState.values.firstName}", lastName: "${formState.values.lastName}"){
            success
            message
        }
        }`);
        setLoading(false);
        if (response.adminRegister) {
            let c = updateCounter + 1;
            setUpdateCounter(c)
        } else if (response) {
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    const resendInvitation = async (s) => {

        const response = await graphQL(`mutation{
        adminResendInvitationEmail(id: "${s._id}"){
            success
            message
        }
        }`);

        if (response.adminResendInvitation) {

        } else if (response) {

        }
    }

    const revokePermission = async (s) => {

        const response = await graphQL(`mutation{
        adminRevokePermission(id: "${s._id}",status:"${s.status}"){
            success
            message
        }
        }`);

        if (response.adminRevokePermission) {
            let c = updateCounter + 1;
            setUpdateCounter(c);
        } else if (response) {

        }
    }

    const renderRows = () => {

        return staff.map((s, index) => {

            return <tr className="clickable-row" key={index} >
                <td>
                    <div className="user-info">
                        <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{s.firstName} {s.lastName}</strong><br />
                        {s.email}
                    </div>
                </td>
                <td>Admin</td>
                <td>{s.status}</td>
                <td>{s.lastLogin}</td>
                <td className="text-center">
                    {s.status === 'invitation sent' ?
                        <a onClick={() => resendInvitation(s)} className="mr-1 btn btn-secondary"><strong>Resend invitation</strong> </a>
                        : ''}
                    <a onClick={() => revokePermission(s)}><strong>{s.status !== 'revoked' ? 'Revoke permission' : 'Grant permission'}</strong></a>
                </td>
            </tr>

        })



    }

    return (
        <AuthContextProvider>>
            <div>
                <div >
                    <div className="container-with-sidebar__heading-bar sticky">
                        <h1 className="container-with-sidebar__title">Manage Staff <small className="isnumber">({staff.length})</small></h1>
                    </div>
                    {/* <!-- Barra create admin user --> */}
                    <div className="utility-bar-wrapper container-with-sidebar__content-block">
                        <form className="utility-bar">
                            <div className="utility-bar-col">
                                <span className="mr-1 p-primary text-bold"><i
                                    className="fas fa-plus-square mr-1"></i>Create Admin User</span>
                                {/* email field*/}
                                <InputWithoutDiv
                                    type="email"
                                    id="email"
                                    placeholder="User email"
                                    onChange={onChange}
                                />
                                {/* .email field */}
                            &nbsp;&nbsp;
                            {/* firstName field*/}
                                <InputWithoutDiv
                                    type="text"
                                    id="firstName"
                                    placeholder="First name"
                                    onChange={onChange}
                                />
                                {/* .firstName field */}
                            &nbsp;&nbsp;
                            {/* lastName field*/}
                                <InputWithoutDiv
                                    type="text"
                                    id="lastName"
                                    placeholder="Last name"
                                    onChange={onChange}
                                />
                                {/* .lastName field */}
                                <button type="submit" onClick={createAdminUser} className="btn btn-secondary ml-1" disabled={disabled}>CREATE</button>
                            </div>

                        </form>
                    </div>
                    {/* <!-- End Barra create admin user -->  */}

                    {loading ?
                        <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                        <section className="container-with-sidebar__content-block">
                            <div className="panel panel-data mt-1">
                                <div className="panel-body">
                                    <div className="table-scroll">
                                        <table className="providers-list">
                                            <tbody>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Rol</th>
                                                    <th>Status</th>
                                                    <th>Last Login</th>
                                                    <th className="text-center">Actions</th>
                                                </tr>
                                                {renderRows()}
                                                {/* <tr className="clickable-row" data-href='account-status.html'>
                                            <td>
                                                <div className="user-info">
                                                    <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>María Perez</strong><br />
                                                    maria@gmail.com
                                        </div>
                                            </td>
                                            <td>Admin</td>
                                            <td>Invitation sent</td>
                                            <td></td>
                                            <td className="text-center"><a href="/manageStaff" className="mr-1 btn btn-secondary"><strong>Resend invitation</strong></a> <a href="/manageStaff"><strong>Revoke permission</strong></a></td>
                                        </tr> */}

                                                {/* <tr className="clickable-row" data-href='account-status.html'>
                                            <td>
                                                <div className="user-info">
                                                    <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>Mary Carmen Lopez</strong><br />
                                                    maria.lopez@nnnp.com
                                        </div>
                                            </td>
                                            <td>Admin</td>
                                            <td>Active</td>
                                            <td>12/31/2018 <span className="time">14:00</span></td>
                                            <td className="text-center"><a href="user-accounts-details.html"><strong>Revoke permission</strong></a></td>
                                        </tr> */}

                                                {/* <tr className="clickable-row" data-href='account-status.html'>
                                            <td>
                                                <div className="user-info">
                                                    <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>María Perez</strong><br />
                                                    maria3456@healthsss.com
                                        </div>
                                            </td>
                                            <td>Admin</td>
                                            <td>Suspended</td>
                                            <td>07/03/2019 <span className="time">11:00</span></td>
                                            <td className="text-center"><a href="/manageStaff"><strong>Grant Permission</strong></a></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5" className="text-center p-primary">
                                                There are no Administrator users. <br /> Use the top bar to add some.
                                    </td>
                                        </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                </div>
            </div>
        </AuthContextProvider>
    );
}

export default ManageStaff;