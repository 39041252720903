import React, { useState, useEffect, useContext } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilter, faTable, faStepBackward, faChevronLeft, faChevronRight, faStepForward, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import history from '../../history';
import graphQL from '../../apis/graphQL';


const ProviderConnectionsTable = (props) => {
    const { columns, data } = props;
    // Use the state and functions returned from useTable to build your UI

    const { setCurrentSelectedUser, currentSelectedNPIFromProvidersList, changeOnProvidersConnections, setChangeOnProvidersConnections } = useContext(LoggedUserContext);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useSortBy,
        usePagination
    )


    const goToUserAccountsDetails = async (_id) => {
        // console.log(_id);
        let response = await graphQL(`mutation{
            adminUserAccountSearch(_id: "${_id}"){
            _id
            profile
            email
            status
            lastLogin
            createdDate
            connectedProviders{
                npi
                firstName
                lastName
                role
                status
                isPMG
            }
            personalInformation{
                firstName
                middleName
                lastName
                suffix
                country
                state
                dateOfBirth
                ssnLast4Digits
                email
                personalPhoneNumber
                homeAddressLine1
                homeAddressLine2
                city
                zipCode
            }
            }
        }`);

        // setLoading(false);
        if (response.adminUserAccountSearch) {
            // setResults(response.adminUserAccountSearch)
            let res = response.adminUserAccountSearch[0];
            setCurrentSelectedUser([
                res.personalInformation.firstName,
                res.personalInformation.lastName,
                res.status,
                res.lastLogin,
                res.createdDate,
                res._id,
                res.connectedProviders
            ]);
            history.push('/userAccountDetails');

        } else if (response) {
            console.log(response);
            // setLoading(false);
            // setGraphQLErrors(response);
        }
        // history.push('/userAccountDetails');
    }

    const disconnect = async (id) => {
        //  setLoading(true);
        const response = await graphQL(`mutation{
        adminRemoveNpi(npi: ${parseInt(currentSelectedNPIFromProvidersList)}, _id: "${id}"){
            npi
            firstName
            lastName
            status
            role
            isPMG
        }
        }`);
        // setLoading(false);
        if (response.adminRemoveNpi) {
            let c = changeOnProvidersConnections + 1;
            setChangeOnProvidersConnections(c);
            console.log(response.adminRemoveNpi);
            // successCode
        } else if (response) {
            // setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    // Render the UI for your table
    return (
        <>
            <table className="providers-list table--has-pagination" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key="hea" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}<span className="table--sort-element"><i className="fas fa-arrow-down"></i>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? <FontAwesomeIcon icon={faArrowDown} />
                                            : <FontAwesomeIcon icon={faArrowUp} />
                                        : ''}
                                </span></th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        let npi = ""
                        return (
                            <tr key={i} className="clickable-row" {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    if (index === 0) {
                                        npi = cell.value[0];
                                        return (<td key={index}  {...cell.getCellProps()}>
                                            <div className="user-info">
                                                <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{cell.value[1]} {cell.value[2]}</strong><br />
                                                {cell.value[3]}
                                            </div>
                                        </td>
                                        )
                                    } else if (index === 3) {
                                        return (
                                            <td key={index} className="text-center">
                                                {/* <Link to={{
                                                    pathname: '/userAccountDetails',
                                                    state: {
                                                        _id: cell.value[5],
                                                        firstName: cell.value[0],
                                                        lastName: cell.value[1],
                                                        status: cell.value[2],
                                                        lastLogin: cell.value[3],
                                                        createdDate: cell.value[4]
                                                    }
                                                }} ><strong>View User Information</strong>
                                                </Link> */}
                                                <a className="p-primary" onClick={() => goToUserAccountsDetails(cell.value)} ><strong>View User Information</strong> &nbsp; &nbsp;</a>
                                                <a onClick={() => disconnect(npi)} className="btn btn-secondary">Disconnect</a>
                                            </td>
                                        )

                                    } else {
                                        return (<td key={index} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>)
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
            <div className="pagination">
                <span className="pagination__controls">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faStepBackward} />
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faStepForward} />
                    </button>
                </span>
                <span> Go to page: &nbsp;
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: "80px", marginLeft: "5px", marginRight: "10px" }}
                    />
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length} ({data.length})
                    </strong>{' '}
                </span>
                <span>

                </span>{' '}

            </div>
        </>
    )
}


export default ProviderConnectionsTable;

