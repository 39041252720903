import React, { useContext, useEffect, useState } from 'react';
import AuthContextProvider from '../../contexts/AuthContext'
import { LoggedUserContext } from '../../contexts/LoggedUser';
import WarningBeforeDisconnnectProvider from '../modals/WarningBeforeDisconnectProvider';
import history from '../../history';
import { Link } from 'react-router-dom';
import graphQL from '../../apis/graphQL';


const UserAccountConnectedProviders = () => {

    const { currentSelectedUser, setCurrentSelectedUser, firstName, lastName, setCurrentSelectedNPIFromProvidersList, setCurrentSelectedNpiFirstNameProvidersList, setCurrentSelectedNpiLastNameProvidersList } = useContext(LoggedUserContext);
    // showModal
    const [showModal, setShowModal] = useState(false);
    // providerInfoForModal
    const [providerInfoForModal, setProviderInfoForModal] = useState({});


    console.log(currentSelectedUser);

    useEffect(() => {
        if (currentSelectedUser === '') {
            history.push('/userAccounts');
        }
        console.log(currentSelectedUser[6]);
    }, []);

    const goToConnectedProviders = (npi, firstName, lastName) => {
        setCurrentSelectedNPIFromProvidersList(npi);
        setCurrentSelectedNpiFirstNameProvidersList(firstName);
        setCurrentSelectedNpiLastNameProvidersList(lastName);
        history.push('/providerConnections');
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const disconnectProvider = async (npi) => {
        // setLoading(true);
        const response = await graphQL(`mutation{
        adminRemoveNpi(npi: ${npi}, _id: "${currentSelectedUser[5]}"){
            npi
            firstName
            lastName
            status
            role
            isPMG
        }
        }`);
        // setLoading(false);
        if (response.adminRemoveNpi) {
            let cu = currentSelectedUser;
            cu[6] = response.adminRemoveNpi;
            setCurrentSelectedUser('');
            setCurrentSelectedUser(cu);
        } else if (response) {
            // setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    const renderRows = () => {
        return currentSelectedUser[6].map((row, index) => {
            console.log(row);
            return (
                <tr className="clickable-row" data-href='' key={index} onClick={() => goToConnectedProviders(row.npi, row.firstName, row.lastName)}>
                    <td><strong>{row.firstName} {row.lastName}</strong><br />
                        <span className="npi">NPI. {row.npi}</span>
                    </td>

                    <td>{row.role === 0 ? 'Primary Representative' : 'Authorized Representative'}</td>
                    <td>{row.status}</td>

                    <td className="text-center"><a onClick={() => goToConnectedProviders(row.npi, row.firstName, row.lastName)} className="p-primary"><strong>View Provider Information</strong></a></td>

                    <td className="text-center"><a onClick={(e) => {
                        setProviderInfoForModal({
                            npi: row.npi,
                            role: row.role,
                            firstName: row.firstName,
                            lastName: row.lastName
                        });
                        e.stopPropagation()
                        setShowModal(true);
                        // disconnectProvider(row.npi)
                    }
                    } className="btn btn-secondary">Disconnect</a></td>
                </tr>
            )
        })
    }

    return (
        <AuthContextProvider>
            {currentSelectedUser === '' ? <div></div> :
                <div>
                    {showModal ? <WarningBeforeDisconnnectProvider closeModalAction={closeModal} provider={providerInfoForModal} disconnect={disconnectProvider} /> : ''}

                    {/* <!-- Main Content--> */}
                    <div>
                        <ul className="container-with-sidebar__breadcrumbs sticky">
                            <li><a href="user-accounts.html">User Accounts</a></li>
                            <li>{currentSelectedUser[0]} {currentSelectedUser[1]}</li>
                            <li className="breadcrumbs--title">
                                <h1>Connected Providers <small className="isnumber">({currentSelectedUser[6].length})</small></h1>
                            </li>
                        </ul>


                        <section className="container-with-sidebar__content-block">
                            <div className="panel panel-data mt-1">
                                <div className="panel-body">
                                    <div className="table-scroll">
                                        <table className="providers-list">
                                            <tbody>
                                                <tr key="tableHeader">
                                                    <th>Provider</th>
                                                    <th>Rol</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                    <th></th>
                                                </tr>

                                                {renderRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
            }
        </AuthContextProvider>
    )
}

export default UserAccountConnectedProviders;