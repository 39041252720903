import React from 'react';

const ProviderDashboard = () => {
    return (
        // <!-- Main Content-->
        <div>
            {/* <!-- Todo: solo para el admin el título de la sección va dentro de los breadcrumbs. Adicional se agrego la clase sticky -->   */}
            <ul className="container-with-sidebar__breadcrumbs sticky">
                <li><a href="provider-accounts.html">Providers</a></li>
                <li>Centro Médico PR</li>
                {/* <!-- TODO: se agregó la clase: breadcrumbs--title al li que contiene el título --> */}
                <li className="breadcrumbs--title"><h1>Dashboard</h1></li>
            </ul>

            {/* <!-- Barra filtros Dashboard --> */}
            <div className="utility-bar-wrapper container-with-sidebar__content-block">
                <div className="utility-bar">
                    <div className="utility-bar-col">
                        <span className="mr-1 p-primary text-bold"><i
                            className="fas fa-filter mr-1"></i>Filter</span>
                        <div className="multiselect-linear-wrapper">
                            <div className="form-combo">
                                <select className="mr-1">
                                    <option value="" selected>Select Range</option>
                                    <option value="01">By month</option>
                                    <option value="02">Greater than</option>
                                    <option value="03">Less than</option>
                                    <option value="04">Between</option>
                                </select><input type="text" />
                            </div>
                            <select className="mr-1">
                                <option value="" disabled selected>By MCO</option>
                                <option value="all">All MCO</option>
                                <option value="11">First Medical</option>
                                <option value="12">MMM</option>
                                <option value="01">Molina Healthcare</option>
                                <option value="02">Plan de Salud Menonita</option>
                                <option value="03">Triple S</option>
                            </select>
                            <select className="mr-1">
                                <option value="" disabled selected>By Timeliness</option>
                                <option value="all">All Range</option>
                                <option value="11">Less than 30d</option>
                                <option value="12">60d</option>
                                <option value="01">90d</option>
                                <option value="02">More Than 90d</option>
                            </select>
                        </div>
                    </div>
                    <div className="utility-bar-col text-right">
                        <a href="" className="btn btn-link"><i className="fas fa-download mr-1"></i>Download Report</a>
                    </div>
                </div>
            </div>
            {/* <!-- End Barra filtros Dashboard --> */}
            <section className="container-with-sidebar__content-block">
                {/* <!-- Dashboard Data Bar --> */}
                <div className="dash-data-bar mt-2 mb-2">
                    <div className="dash-data-bar__title">
                        <h2>YTD</h2>
                    </div>
                    <div className="dash-data-bar__information">
                        <div className="dash-data-bar__element">
                            <div className="dash-data-bar__label">Encounter Transactions</div>
                            <div className="dash-data-bar__value">999999</div>
                        </div>
                        <div className="dash-data-bar__element">
                            <div className="dash-data-bar__label">Claims Transactions</div>
                            <div className="dash-data-bar__value">999999</div>
                        </div>
                        <div className="dash-data-bar__element">
                            <div className="dash-data-bar__label">Amount Allowed</div>
                            <div className="dash-data-bar__value"><span className="money">$</span>&nbsp;99,999,000</div>
                        </div>
                        <div className="dash-data-bar__element">
                            <div className="dash-data-bar__label">Amount Paid</div>
                            <div className="dash-data-bar__value"><span className="money">$</span>&nbsp;99,999,000</div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Dashboard Data Bar -->
                <!-- Dashboard Data Bar No Data --> */}
                <div className="dash-data-bar mt-2 mb-2">
                    <div className="dash-data-bar__title">
                        <h2>YTD</h2>
                    </div>
                    <div className="dash-data-bar__information">
                        <span className="p-primary">There is no available data to display for the current selection</span>
                    </div>
                </div>
                {/* <!-- End Dashboard Data Bar No Data -->
                <!-- Grupo de Gráficos --> */}
                <div className="row">
                    <div className="col">
                        <div className="panel">
                            <div className="panel-head">
                                <div className="title">
                                    <h3>Amount Paid by MCO</h3>
                                </div>
                                <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i
                                    className="fas fa-table"></i></a></div>
                            </div>
                            <div className="panel-body">
                                <canvas id="myChart"></canvas>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="panel">
                            <div className="panel-head">
                                <div className="title">
                                    <h3>Transactions by Status</h3>
                                </div>
                                <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i
                                    className="fas fa-table"></i></a></div>
                            </div>
                            <div className="panel-body">
                                <div className="message-no-data">
                                    <img src="images/image-no-data-to-display.png" alt="No data image" />
                                    <p className="p-primary">There is no available data to display for the current selection</p>
                                </div>
                                {/* <!-- <canvas id="myChart"></canvas> --> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="panel">
                            <div className="panel-head">
                                <div className="title">
                                    <h3>Timeliness for Regular Claims<br />
                                        <small>(Date Received Vs. Date Paid)</small></h3>
                                </div>
                                <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i
                                    className="fas fa-table"></i></a></div>
                            </div>
                            <div className="panel-body">
                                <canvas id="mixChart"></canvas>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="panel">
                            <div className="panel-head">
                                <div className="title">
                                    <h3>Timeliness for Adjusted Claims <br />
                                        <small>(Date Received Vs. Date Paid)</small></h3>
                                </div>
                                <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i
                                    className="fas fa-table"></i></a></div>
                            </div>
                            <div className="panel-body">
                                <div className="message-no-data">
                                    <img src="images/image-no-data-to-display.png" alt="No data image" />
                                    <p className="p-primary">There is no available data to display for the current selection</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel mt-2">
                    <div className="panel-head">
                        <div className="title">
                            <h3>Claims - Total Amount Allowed and Paid by Month ($)</h3>
                        </div>
                        <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i className="fas fa-table"></i></a>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="message-no-data">
                            <img src="images/image-no-data-to-display.png" alt="No data image" />
                            <p className="p-primary">There is no available data to display for the current selection</p>
                        </div>
                    </div>
                </div>
                <div className="panel mb-4 mt-2">
                    <div className="panel-head">
                        <div className="title">
                            <h3>Encounters - Total Transaccions by Month</h3>
                        </div>
                        <div className="util text-right"><a href="provider-dashboard-table.html" className="btn"><i className="fas fa-table"></i></a>
                        </div>
                    </div>
                    <div className="panel-body">
                        Here goes the graph
                    </div>
                </div>
                {/* <!-- End Grupo de Gráficos -->             */}

            </section>
        </div>
    );
}

export default ProviderDashboard;