import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilter, faTable } from '@fortawesome/free-solid-svg-icons';
import AuthContextProvider from '../../contexts/AuthContext';

const Dashboard = () => {
    return (
        <AuthContextProvider>
            <div>
                {/* <!-- Main Content--> */}
                < div >
                    <div className="container-with-sidebar__heading-bar sticky">
                        <h1 className="container-with-sidebar__title">Dashboard</h1>
                    </div>

                    {/* <!-- Barra filtros Dashboard --> */}
                    <div className="utility-bar-wrapper container-with-sidebar__content-block">
                        <div className="utility-bar">
                            <div className="utility-bar-col">
                                <span className="mr-1 p-primary text-bold"><FontAwesomeIcon icon={faFilter} className="mr-1" />Filter</span>
                                <div className="multiselect-linear-wrapper">
                                    <div className="form-combo">
                                        <select className="mr-1">
                                            <option value="">Select Range</option>
                                            <option value="01">By month</option>
                                            <option value="02">Greater than</option>
                                            <option value="03">Less than</option>
                                            <option value="04">Between</option>
                                        </select><input type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="utility-bar-col text-right">
                                <a href="/dashboard" className="btn btn-link"><FontAwesomeIcon icon={faDownload} className="mr-1" /> Download Report</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Barra filtros Dashboard --> */}

                    <section className="container-with-sidebar__content-block">
                        {/* <!-- Grupo de Gráficos --> */}
                        <div className="row mt-2">
                            <div className="col">
                                <div className="panel">
                                    <div className="panel-head panel-head--no-bottom-pad">
                                        <div className="title">
                                            <h3>Last Hits</h3>
                                        </div>
                                        <div className="util text-right"><a href="/dashboard" className="btn"><FontAwesomeIcon icon={faTable} /></a></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="count-tag mb-2">Total Users: XXXXXX</div>
                                        <canvas id="myLineChart"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="panel">
                                    <div className="panel-head panel-head--no-bottom-pad">
                                        <div className="title">
                                            <h3>Connections</h3>
                                        </div>
                                        <div className="util text-right"><a href="/dashboard" className="btn"><FontAwesomeIcon icon={faTable} /></a></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="count-tag mb-2">Total Providers: XXXXXX</div>
                                        <canvas id="myChart"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <div className="panel">
                                    <div className="panel-head">
                                        <div className="title">
                                            <h3>Empty data example</h3>
                                        </div>
                                        <div className="util text-right"><a href="/dashboard" className="btn"><FontAwesomeIcon icon={faTable} /></a></div>
                                    </div>
                                    <div className="panel-body">

                                        <div className="message-no-data">
                                            <img src="/images/image-no-data-to-display.png" alt="No data" />
                                            <p className="p-primary">There is no available data to display for the current selection</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div >
            </div >
        </AuthContextProvider>
    );
}

export default Dashboard;