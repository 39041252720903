import React, { useState, useEffect } from 'react';
import { default as S } from 'react-select';
import graphQL from '../../apis/graphQL'
import { Link } from 'react-router-dom';
import UserAccountsTable from './UserAccountsTable';
import AuthContextProvider from '../../contexts/AuthContext';

const UserAccounts = () => {

    // REACT TABLES
    const columns = React.useMemo(
        () => [
            {
                Header: 'User',
                accessor: 'user',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Last Login',
                accessor: 'lastLogin',
            },
            {
                Header: 'Actions',
                accessor: 'actions',
            },
        ],
        []
    )

    // .REACT TABLES

    const filter = {
        id: 'filter',
        options: [
            {
                value: '',
                label: 'Filter by'
            },
            {
                label: 'Email',
                value: 'email'
            },
            // TODO: pending to implement
            // {
            //     label: 'Name',
            //     value: 'name'
            // },
            // {
            //     label: 'Status',
            //     value: 'status'
            // }
        ],
        defaultValue: {
            value: 'email',
            label: 'Email'
        }
    };

    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(true);
    // disabledSearchButton
    const [disabledSearchButton, setDisabledSearchButton] = useState(true);
    // showFilterResultsText
    const [showFilterResultsText, setShowFilterResultsText] = useState(false);
    // data
    const [data, setData] = useState([]);
    // optionSelected
    const [optionSelected, setOptionSelected] = useState(filter.defaultValue);
    // selectedFilterOption
    const [selectedFilterOption, setSelectedFilterOption] = useState('email');
    // inputFilter
    const [inputFilter, setInputFilter] = useState('');
    // loading
    const [loading, setLoading] = useState(false);
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState([]);
    // results
    const [results, setResults] = useState([]);

    const onChange = (e) => {
        setInputFilter(e.target.value);
    }


    //Disable/Enable searchButton
    useEffect(() => {
        setShowFilterResultsText(false);
        if (inputFilter !== '' && selectedFilterOption !== '') {
            setDisabledSearchButton(false);
        } else {
            setDisabledSearchButton(true);
        }
    }, [inputFilter, selectedFilterOption])

    useEffect(() => {
        (async () => {
            let response = await graphQL(`mutation{
                    adminUserAccountSearch(type: "${selectedFilterOption}", value: {
                        email:"${inputFilter}"
                        }){
                    _id
                    profile
                    email
                    status
                    lastLogin
                    createdDate
                    connectedProviders{
                        npi
                        firstName
                        lastName
                        role
                        status
                        isPMG
                    }
                    personalInformation{
                        firstName
                        middleName
                        lastName
                        suffix
                        country
                        state
                        dateOfBirth
                        ssnLast4Digits
                        email
                        personalPhoneNumber
                        homeAddressLine1
                        homeAddressLine2
                        city
                        zipCode
                    }
                    }
                }`);

            setLoading(false);
            if (response.adminUserAccountSearch) {
                setLoadingScreen(false);
                setResults(response.adminUserAccountSearch)
                let dataArray = []
                response.adminUserAccountSearch.forEach((user) => {
                    let firstName = '';
                    let lastName = '';
                    try {
                        firstName = user.personalInformation.firstName
                        lastName = user.personalInformation.lastName
                    } catch (e) {

                    }
                    dataArray.push({
                        user: [firstName, lastName, user.email],
                        status: user.status,
                        lastLogin: user.lastLogin,
                        actions: [firstName, lastName, user.status, user.lastLogin, user.createdDate, user._id, user.connectedProviders]
                    })
                })
                setData([]);
                setData(dataArray);

                console.log(response.adminUserAccountSearch)
            } else if (response) {
                setLoadingScreen(false);
                console.log(response);
                setLoading(false);
                setGraphQLErrors(response);
            }
        })()
    }, [])

    const search = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response = '';
        console.log(inputFilter);
        if (selectedFilterOption === 'email' || selectedFilterOption === "") {
            setLoadingScreen(true);
            response = await graphQL(`mutation{
                    adminUserAccountSearch(type: "${selectedFilterOption}", value: {
                        email:"${inputFilter}"
                        }){
                    profile
                    email
                    status
                    lastLogin
                    createdDate
                    connectedProviders{
                        npi
                        firstName
                        lastName
                        role
                        status
                        isPMG
                    }
                    personalInformation{
                        firstName
                        middleName
                        lastName
                        suffix
                        country
                        state
                        dateOfBirth
                        ssnLast4Digits
                        email
                        personalPhoneNumber
                        homeAddressLine1
                        homeAddressLine2
                        city
                        zipCode
                    }
                    }
                }`);
        }
        setLoading(false);
        if (response.adminUserAccountSearch) {
            setShowFilterResultsText(true);
            setLoadingScreen(false);
            setResults(response.adminUserAccountSearch)
            let dataArray = []
            response.adminUserAccountSearch.forEach((user) => {
                let firstName = '';
                let lastName = '';
                try {
                    firstName = user.personalInformation.firstName
                    lastName = user.personalInformation.lastName
                } catch (e) {

                }
                dataArray.push({
                    user: [firstName, lastName, user.email],
                    status: user.status,
                    lastLogin: user.lastLogin,
                    actions: [firstName, lastName, user.status, user.lastLogin, user.createdDate, user._id, user.connectedProviders]
                })
            })
            setData([]);
            setData(dataArray);

            console.log(response.adminUserAccountSearch)
        } else if (response) {
            console.log(response);
            setLoadingScreen(false);
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    return (
        // <!-- Main Content-->
        <AuthContextProvider>
            <div>

                <div className="container-with-sidebar__heading-bar sticky">
                    <h1 className="container-with-sidebar__title">User Accounts
                    <small className="isnumber">
                            {/* ({results.length} users found) */}
                        </small>
                    </h1>
                </div>

                {/* <!-- Barra search provider --> */}
                <div className="utility-bar-wrapper container-with-sidebar__content-block">
                    <form className="utility-bar">
                        <div className="utility-bar-col">
                            <span className="mr-1 p-primary text-bold"><i
                                className="fas fa-search mr-1"></i>User Search</span>
                            <div className="multiselect-linear-wrapper">
                                {/* Filter Dropdown */}
                                <S options={filter.options} className="select-form-combo" id={filter.id} name={filter.id} onChange={(option) => {
                                    setOptionSelected(option);
                                    setSelectedFilterOption(option.value)
                                }
                                }
                                    value={optionSelected}
                                />
                                {/* .Filter Dropdown */}
                            </div>
                            <input type="text" placeholder="Ex. jon@test.com" id="inputFilter" name="inputFilter" onChange={onChange} />

                            <button disabled={disabledSearchButton} className="btn btn-secondary ml-1" onClick={search} type="submit">
                                SEARCH
                            </button>
                        </div>
                    </form>
                </div>
                {/* <!-- End Barra search provider -->  */}

                {loadingScreen ? <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                    <section className="container-with-sidebar__content-block">
                        <div className="results mt-2 mb-2">
                            {showFilterResultsText ? 'Search Results for ' : ''}
                            <strong>
                                {showFilterResultsText ? `${selectedFilterOption}: ${inputFilter}` : ''}
                            </strong>
                        </div>
                        <div className="panel panel-data mt-1">
                            <div className="panel-body">
                                <div className="table-scroll">
                                    {data.length === 0 ?
                                        <table className="providers-list">
                                            <tbody>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Status</th>
                                                    <th>Last Login</th>
                                                    <th>Actions</th>
                                                </tr>

                                                <tr>
                                                    <td colSpan="4" className="text-center p-primary">
                                                        There is no results for your search. <br /> Check your parameters, or make a wider search.
                                                </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        :
                                        <UserAccountsTable columns={columns} data={data} />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        </AuthContextProvider>
    );
}

export default UserAccounts;