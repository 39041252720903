import React, { useState, useEffect } from 'react';
import { default as S } from 'react-select';
import graphQL from '../../apis/graphQL'
import ProviderAccountsTable from './ProviderAccountsTable';

const ProviderAccounts = () => {

    // REACT TABLES
    const columns = React.useMemo(
        () => [
            {
                Header: 'Provider',
                accessor: 'provider',
            },
            {
                Header: 'Municipality',
                accessor: 'municipality',
            },
            {
                Header: 'Primary Representative',
                accessor: 'primaryRepresentative',
            },
            {
                Header: ' ',
                accessor: 'action',
            },
        ],
        []
    )

    // .REACT TABLES

    const filter = {
        id: 'filter',
        options: [
            {
                value: '',
                label: 'Filter by'
            },
            {
                label: 'NPI',
                value: 'npi'
            },
            {
                label: 'Provider Name',
                value: 'providerName'
            },
            {
                label: 'Rep Email',
                value: 'repEmail'
            }
        ],
        defaultValue: {
            value: 'npi',
            label: 'NPI'
        }
    };

    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(false);
    // data
    const [data, setData] = useState([]);
    // disabledSearchButton
    const [disabledSearchButton, setDisabledSearchButton] = useState(true);
    // showFilterResultsText
    const [showFilterResultsText, setShowFilterResultsText] = useState(false);
    // initialNoSearch
    const [initialNoSearch, setInitialNoSearch] = useState(true);
    // optionSelected
    const [optionSelected, setOptionSelected] = useState(filter.defaultValue);
    // selectedFilterOption
    const [selectedFilterOption, setSelectedFilterOption] = useState('npi');
    // inputFilter
    const [inputFilter, setInputFilter] = useState('');
    // loading
    const [loading, setLoading] = useState(false);
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState([]);
    // results
    const [results, setResults] = useState([]);

    //Disable/Enable searchButton
    useEffect(() => {
        setShowFilterResultsText(false);
        if (inputFilter !== '' && selectedFilterOption !== '') {
            setDisabledSearchButton(false);
        } else {
            setDisabledSearchButton(true);
        }
    }, [inputFilter, selectedFilterOption])

    const onChange = (e) => {
        setShowFilterResultsText(false);
        setInputFilter(e.target.value);
    }

    const search = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLoadingScreen(true);
        setInitialNoSearch(false);
        let response = '';
        if (selectedFilterOption === 'npi') {
            response = await graphQL(`mutation{
            adminProviderSearch(type: "${selectedFilterOption}", value: {
                npi:"${inputFilter}"
                }){
                success
                results{
                    npiAlreadyAssociated
                    npi
                    primaryRepresentativeEmail
                    primaryRepresentativeFirstName
                    primaryRepresentativeLastName
                    cities
                    firstName
                    lastName
                    error
                }
                }
            }`);
        } else if (selectedFilterOption === 'providerName') {
            response = await graphQL(`mutation{
            adminProviderSearch(type: "${selectedFilterOption}", value: {
                providerName:"${inputFilter}"
                }){
                success
                results{
                    npiAlreadyAssociated
                    npi
                    primaryRepresentativeEmail
                    primaryRepresentativeFirstName
                    primaryRepresentativeLastName
                    cities
                    firstName
                    lastName
                    error
                }
                }
            }`);
        } else {
            response = await graphQL(`mutation{
                adminProviderSearch(type: "${selectedFilterOption}", value: {
                    repEmail:"${inputFilter}"
                    }){
                    success
                    results{
                        npiAlreadyAssociated
                        npi
                        primaryRepresentativeEmail
                        primaryRepresentativeFirstName
                        primaryRepresentativeLastName
                        cities
                        firstName
                        lastName
                        error
                    }
                    }
            }`);
        }
        setLoading(false);
        if (response.adminProviderSearch) {
            setLoadingScreen(false);
            console.log(response.adminProviderSearch);
            setShowFilterResultsText(true);

            if (response.adminProviderSearch.success) {

                setInitialNoSearch(false);
                setResults(response.adminProviderSearch.results)

                let dataArray = [];
                response.adminProviderSearch.results.forEach((provider) => {
                    let npi = "";
                    let firstName = "";
                    let lastName = "";
                    let primaryRepresentativeFirstName = "";
                    let primaryRepresentativeLastName = "";
                    let primaryRepresentativeEmail = "";
                    let npiAlreadyAssociated = false;
                    let cities = "";

                    if (provider.npiAlreadyAssociated) {
                        npi = provider.npi;
                        firstName = provider.firstName;
                        lastName = provider.lastName;
                        primaryRepresentativeFirstName = provider.primaryRepresentativeFirstName;
                        primaryRepresentativeLastName = provider.primaryRepresentativeLastName;
                        primaryRepresentativeEmail = provider.primaryRepresentativeEmail
                        npiAlreadyAssociated = true;
                        cities = provider.cities;
                    }
                    else {
                        npi = provider.npi;
                        firstName = provider.firstName;
                        lastName = provider.lastName;
                    }

                    dataArray.push({
                        provider: [npi, firstName, lastName],
                        municipality: cities,
                        primaryRepresentative: [npiAlreadyAssociated, primaryRepresentativeFirstName, primaryRepresentativeLastName, primaryRepresentativeEmail],
                        action: ''
                    })

                });

                setData([]);
                setData(dataArray);
            } else {
                setData([]);
            }

            console.log(response.adminProviderSearch.results)
        } else if (response) {
            console.log(response);
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    const renderProvidersList = () => {
        if (results.length === 0) {
            return (
                <tr>
                    <td colSpan="4" className="text-center p-primary">
                        There is no results for your search. <br /> Check your parameters, or make a wider search.
            </td>
                </tr>
            )
        } else {
            return results.map((res, index) => {
                if (res.npiAlreadyAssociated) {
                    return <tr key={index} className="clickable-row" data-href='provider-dashboard.html'>

                        <td>
                            <strong>{res.firstName} {res.lastName}</strong><br />
                            <span className="npi">NPI. {res.npi}</span>
                        </td>

                        <td>{res.cities}</td>

                        <td>
                            <div className="user-info">
                                <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{res.primaryRepresentativeFirstName} {res.primaryRepresentativeLastName}</strong><br />
                                {res.primaryRepresentativeEmail}
                            </div>
                        </td>
                        <td className="text-center"><a href="provider-dashboard.html" className="p-primary"><strong>View Provider Information</strong></a></td>
                    </tr>
                } else {
                    return <tr key={index} className="clickable-row" data-href='provider-dashboard.html'>

                        <td><strong>{res.firstName} {res.lastName}</strong><br />
                            <span className="npi">NPI. {res.npi}</span>
                        </td>

                        <td></td>

                        <td> Not Claimed </td>

                        <td className="text-center"><a href="provider-dashboard.html" className="p-primary"><strong>View Provider Information</strong></a></td>
                    </tr>
                }
            })
        }
    }

    return (
        // <!-- Main Content-->
        <div>
            <div className="container-with-sidebar__heading-bar sticky">
                <h1 className="container-with-sidebar__title">Providers
                <small className="isnumber">
                        {/* ({results.length}) */}
                    </small>
                </h1>
            </div>

            {/* <!-- Barra search provider --> */}
            <div className="utility-bar-wrapper container-with-sidebar__content-block">
                <form className="utility-bar" >
                    <div className="utility-bar-col">
                        <span className="mr-1 p-primary text-bold"><i
                            className="fas fa-search mr-1"></i>Provider Search</span>

                        <div className="multiselect-linear-wrapper">
                            {/* Filter Dropdown */}
                            <S options={filter.options} className="select-form-combo" id={filter.id} name={filter.id} onChange={(option) => {
                                setOptionSelected(option);
                                setSelectedFilterOption(option.value)
                            }
                            }
                                value={optionSelected}
                            />
                            {/* .Filter Dropdown */}
                        </div>
                        <input type="text" placeholder="Ex. 1234567890" id="inputFilter" name="inputFilter" onChange={onChange} />
                        <button disabled={disabledSearchButton} className="btn btn-secondary ml-1" onClick={search} type="submit">
                            SEARCH
                        </button>
                    </div>
                </form>
            </div>
            {/* <!-- End Barra search provider -->  */}


            {initialNoSearch ?
                <section className="container-with-sidebar__content-block">
                    <div className="row mt-5 mb-5">
                        <div className="col text-right">
                            <img className="mr-2" src="images/ilustracion-empty-providers-search.png" alt="Icons depicting a user provider search" />
                        </div>
                        <div className="col-grow-2">
                            <h1>
                                Search for providers
                            </h1>
                            <p className="p-primary">
                                Use the top search bar
                            </p>
                        </div>
                    </div>
                </section>
                : loadingScreen ? <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                    <section className="container-with-sidebar__content-block">
                        <div className="results mt-2 mb-2">
                            {showFilterResultsText ? 'Search Results for ' : ''}
                            <strong>
                                {showFilterResultsText ? `${selectedFilterOption}: ${inputFilter}` : ''}
                            </strong>
                        </div>
                        <div className="panel panel-data mt-1">
                            <div className="panel-body">
                                <div className="table-scroll">
                                    {data.length === 0 ?
                                        <table className="providers-list">
                                            <tbody>
                                                <tr>
                                                    <th>Provider</th>
                                                    <th>Municipality</th>
                                                    <th>Primary Representative</th>
                                                    <th></th>
                                                </tr>

                                                <tr>
                                                    <td colSpan="4" className="text-center p-primary">
                                                        There is no results for your search. <br /> Check your parameters, or make a wider search.
                                                </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        :
                                        < ProviderAccountsTable columns={columns} data={data} />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </div>
    );
}

export default ProviderAccounts;