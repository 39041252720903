import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-date-picker';
import './DatePicker.css'

const Input = (props) => {
    const { id, placeholder, customClass, label, errorMessage, disabled, optional, fancy, stacked, mt2, onChange, onBlur, value, maxLength, invisibleFancy } = props;
    const applyCustomClass = customClass || '';
    const applyEmptyField = errorMessage ? 'missing-field' : '';

    const [showPassword, changeShowPassword] = useState(false);
    const [type, changeType] = useState(props.type);
    const isPassword = props.type === 'password' ? true : false;

    return (
        <span className={fancy ? "form-group fancy" : invisibleFancy ? "form-group" : stacked ? "form-group form-stacked" : mt2 ? "form-group mt-2" : ""}>
            {fancy || invisibleFancy ?
                <label htmlFor={id}>
                    <span className="sr-only">{label}</span>
                    {type === 'password' ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUser} />}
                </label>
                :
                <label htmlFor={id}>{label}</label>
            }

            {type !== 'date' ?
                <input
                    disabled={disabled ? true : false}
                    type={type}
                    id={id}
                    name={id}
                    placeholder={placeholder}
                    className={`${applyEmptyField} ${applyCustomClass}`}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxLength={maxLength}
                />
                :
                <DatePicker
                    onChange={onChange}
                    value={value}
                />
            }

            {optional ? <span className="optional">(Optional)</span> : ''}
            {isPassword ?
                <span className="help" onClick={() => {
                    changeShowPassword(!showPassword)
                    if (showPassword) {
                        changeType('password');
                    } else {
                        changeType('text');
                    }
                }
                }
                >
                    {showPassword ?
                        <FontAwesomeIcon icon={faEye} />
                        :
                        <FontAwesomeIcon icon={faEyeSlash} />
                    }
                </span>
                : ''
            }
            {errorMessage ? <div className="missing-field-message">{errorMessage}</div> : ''}
        </span>
    );
}

Input.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    showPassword: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Input;

