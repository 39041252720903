import React, { useContext, useEffect, useState } from 'react';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import graphQL from '../../apis/graphQL';

const WarningBeforDisconnectProvider = (props) => {

    const { firstName, lastName } = useContext(LoggedUserContext);
    // modalNpi
    const [modalNpi, setModalNpi] = useState(0);

    useEffect(() => {
        (async () => {
            //setLoading(true);
            const response = await graphQL(`query{
                adminGetAuthorizedRepresentativesCounter(npi: ${props.provider.npi})
            }`);
            //setLoading(false);
            if (response.adminGetAuthorizedRepresentativesCounter) {
                setModalNpi(response.adminGetAuthorizedRepresentativesCounter);
            } else if (response) {
                // setLoading(false);
                // setGraphQLErrors(response);
            }
        })()
    }, []);

    return (
        <div id="modal-suspend" className="overlay">
            <div className="modal">
                <h1>Suspend/Delete Account</h1>
                <a className="close" href="#"><i className="fas fa-times-circle"></i></a>
                <div className="content">
                    <p><img className="mb-2" src="images/image-attention.png" /></p>
                    <p>If you continue, <strong>{firstName} {lastName} will no longer be able to see information from Provider {props.provider.firstName} {props.provider.lastName}.</strong></p>
                    {props.provider.role === 0 && modalNpi > 0 ?
                        <p>This user is a Primary Representative of this provider. She/He <strong>has authorized {modalNpi} user(s) as Authorized Representatives. They will be disconnected as well.</strong></p>
                        : ''}
                    <div className="form-group mt-3">
                        <a onClick={props.closeModalAction} className="btn btn-secondary mr-2">Cancel</a>
                        <a onClick={() => {
                            props.disconnect(props.provider.npi)
                            props.closeModalAction()
                        }} className="btn btn-primary mr-2">Disconnect</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WarningBeforDisconnectProvider;