import React, { useEffect, useState } from 'react';
import { default as S } from 'react-select';
import graphQL from '../../apis/graphQL';
import ChangeLogTable from './ChangeLogTable';
import AuthContextProvider from '../../contexts/AuthContext';


const ChangeLog = () => {

    // REACT TABLES
    const columns = React.useMemo(
        () => [
            {
                Header: 'TIME STAMP',
                accessor: 'timeStamp',
            },
            {
                Header: 'USER ACCOUNT / PROVIDER',
                accessor: 'userAccount',
            },
            {
                Header: 'CHANGE',
                accessor: 'action',
            },
            {
                Header: 'CHANGE BY',
                accessor: 'adminAccount',
            },
        ],
        []
    )

    // .REACT TABLES

    const filter = {
        id: 'filter',
        options: [
            {
                value: '',
                label: 'Filter by'
            },
            {
                label: 'Email',
                value: 'email'
            },
            // TODO: pending to implement 
            // {
            //     label: 'Name',
            //     value: 'name'
            // },
            // {
            //     label: 'Status',
            //     value: 'status'
            // }
        ],
        defaultValue: {
            value: '',
            label: 'Filter By'
        }
    };

    // loadingScreen
    const [loadingScreen, setLoadingScreen] = useState(true);
    // optionSelected
    const [optionSelected, setOptionSelected] = useState(filter.defaultValue);
    // previous
    const [previous, setPrevious] = useState('');
    const [next, setNext] = useState('');
    // pageIndex
    const [pageIndex, setPageIndex] = useState(0);
    // disabledSearchButton
    const [disabledSearchButton, setDisabledSearchButton] = useState(true);
    // showFilterResultsText
    const [showFilterResultsText, setShowFilterResultsText] = useState(false);

    // selectedFilterOption
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    // inputFilter
    const [inputFilter, setInputFilter] = useState('');
    // graphQLErrors
    const [graphQLErrors, setGraphQLErrors] = useState([]);

    const search = async (e) => {
        e.preventDefault();
        setLoadingScreen(true);
        if (selectedFilterOption === 'email' || selectedFilterOption === "") {
            console.log(selectedFilterOption);
            console.log(inputFilter);
            const response = await graphQL(`query{
                adminGetChangeLog(type: "fullTextSearch", value: {
                    fullTextSearch:"${inputFilter}"
                    }){
                    result{
                    timeStamp
                    userAccount{
                        firstName
                        lastName
                        email
                    }
                    action
                    adminAccount{
                        firstName
                        lastName
                        title
                    }
                    }
                    next
                    previous
                }
                }`);
            // setLoading(false);
            if (response.adminGetChangeLog) {
                setLoadingScreen(false);
                let dataArray = [];
                setNext(response.adminGetChangeLog.next);
                setPrevious(response.adminGetChangeLog.previous);
                response.adminGetChangeLog.result.forEach((res) => {
                    dataArray.push({
                        timeStamp: res.timeStamp,
                        userAccount: [res.userAccount.firstName, res.userAccount.lastName, res.userAccount.email],
                        action: res.action,
                        adminAccount: [res.adminAccount.firstName, res.adminAccount.lastName, res.adminAccount.title]
                    })
                })
                console.log(dataArray);
                setLogData([]);
                setLogData(dataArray);
            } else if (response) {
                setLoadingScreen(false);
                // setLoading(false);
                // setGraphQLErrors(response);
            }
        }
    }

    // logData
    const [logData, setLogData] = useState([]);


    const addMoreData = async (pageI) => {
        setPageIndex(pageI);
        // setLoading(true);
        const response = await graphQL(`query{
        adminGetChangeLog(next:"${next}"){
            result{
            timeStamp
            userAccount{
                firstName
                lastName
                email
            }
            action
            adminAccount{
                firstName
                lastName
                title
            }
            }
            next
            previous
        }
        }`);
        // setLoading(false);
        if (response.adminGetChangeLog) {

            console.log(response.adminGetChangeLog);

            let dataArray = logData;
            setNext(response.adminGetChangeLog.next);
            setPrevious(response.adminGetChangeLog.previous);
            response.adminGetChangeLog.result.forEach((res) => {
                dataArray.push({
                    timeStamp: res.timeStamp,
                    userAccount: [res.userAccount.firstName, res.userAccount.lastName, res.userAccount.email],
                    action: res.action,
                    adminAccount: [res.adminAccount.firstName, res.adminAccount.lastName, res.adminAccount.title]
                })
            })
            console.log(dataArray);
            setLogData([]);
            setLogData(dataArray);


        } else if (response) {
            // setLoading(false);
            // setGraphQLErrors(response);
        }
    }

    //Disable/Enable searchButton
    useEffect(() => {
        setShowFilterResultsText(false);
        if (inputFilter !== '' && selectedFilterOption !== '') {
            setDisabledSearchButton(false);
        } else {
            setDisabledSearchButton(true);
        }
    }, [inputFilter, selectedFilterOption])

    useEffect(() => {
        (async () => {
            // setLoading(true);
            const response = await graphQL(`query{
            adminGetChangeLog{
                result{
                timeStamp
                userAccount{
                    firstName
                    lastName
                    email
                }
                action
                adminAccount{
                    firstName
                    lastName
                    title
                }
                }
                next
                previous
            }
            }`);
            // setLoading(false);
            if (response.adminGetChangeLog) {
                let dataArray = [];
                setLoadingScreen(false);
                setNext(response.adminGetChangeLog.next);
                setPrevious(response.adminGetChangeLog.previous);
                response.adminGetChangeLog.result.forEach((res) => {
                    dataArray.push({
                        timeStamp: res.timeStamp,
                        userAccount: [res.userAccount.firstName, res.userAccount.lastName, res.userAccount.email],
                        action: res.action,
                        adminAccount: [res.adminAccount.firstName, res.adminAccount.lastName, res.adminAccount.title]
                    })
                })
                setLogData(dataArray);


            } else if (response) {
                // setLoading(false);
                // setGraphQLErrors(response);
            }
        })()
    }, [])

    const renderRows = () => {
        return logData.map((log, index) => {
            return (
                <tr className="clickable-row" data-href='changeLog' key={index}>
                    <td>{log.timeStamp}</td>

                    <td>
                        <div className="user-info">
                            <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{log.userAccount.firstName} {log.userAccount.lastName}</strong><br />
                            {log.userAccount.email}
                        </div>
                    </td>

                    <td>{log.action}</td>

                    <td>
                        <div className="user-info">
                            <span className="user-info__thumbnail"><img src="images/user-circle.png" alt="User Name" /></span><strong>{log.adminAccount.firstName} {log.adminAccount.lastName}</strong><br />
                            {log.adminAccount.title}
                        </div>
                    </td>
                </tr>
            )
        })
    }

    const onChange = async (e) => {
        setInputFilter(e.target.value);
        setLoadingScreen(true);
        const response = await graphQL(`query{
            adminGetChangeLog(type: "fullTextSearch", value: {
                fullTextSearch:"${e.target.value}"
                }){
                result{
                timeStamp
                userAccount{
                    firstName
                    lastName
                    email
                }
                action
                adminAccount{
                    firstName
                    lastName
                    title
                }
                }
                next
                previous
            }
            }`);
        // setLoading(false);
        if (response.adminGetChangeLog) {
            setLoadingScreen(false);
            let dataArray = [];
            setNext(response.adminGetChangeLog.next);
            setPrevious(response.adminGetChangeLog.previous);
            response.adminGetChangeLog.result.forEach((res) => {
                dataArray.push({
                    timeStamp: res.timeStamp,
                    userAccount: [res.userAccount.firstName, res.userAccount.lastName, res.userAccount.email],
                    action: res.action,
                    adminAccount: [res.adminAccount.firstName, res.adminAccount.lastName, res.adminAccount.title]
                })
            })
            console.log(dataArray);
            setLogData([]);
            setLogData(dataArray);
        } else if (response) {
            setLoadingScreen(false);
            // setLoading(false);
            // setGraphQLErrors(response);
        }
    }


    return (
        <AuthContextProvider>
            <div>

                <div className="container-with-sidebar__heading-bar sticky">
                    <h1 className="container-with-sidebar__title">Change Log</h1>
                </div>

                {/* <!-- Barra search provider --> */}
                <div className="utility-bar-wrapper container-with-sidebar__content-block">
                    <form className="utility-bar">
                        <div className="utility-bar-col">
                            <span className="mr-1 p-primary text-bold"><i
                                className="fas fa-search mr-1"></i>Search By Any field</span>
                            {/* <div className="multiselect-linear-wrapper">
                               
                                <S options={filter.options} className="select-form-combo" id={filter.id} name={filter.id} onChange={(option) => {
                                    setOptionSelected(option);
                                    setSelectedFilterOption(option.value)
                                }
                                }
                                    value={optionSelected}
                                />
                              
                            </div> */}
                            <input type="text" placeholder="Ex. jon@test.com" id="inputFilter" name="inputFilter" onChange={onChange} />

                            {/* <button disabled={disabledSearchButton} className="btn btn-secondary ml-1" onClick={search} type="submit">
                                SEARCH
                            </button> */}
                        </div>

                    </form>
                </div>
                {/* <!-- End Barra search provider --> */}
                {loadingScreen ? <div class="loading" style={{ marginTop: '150px' }}><div class="loader-screen"></div></div> :
                    <section className="container-with-sidebar__content-block">
                        <div className="panel panel-data mt-1">
                            <div className="panel-body">
                                <div className="table-scroll">
                                    <table className="providers-list">
                                        <tbody>

                                            <ChangeLogTable columns={columns} data={logData} getPaginationData={addMoreData} pageIndex={pageIndex} />

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        </AuthContextProvider>
    );
}

export default ChangeLog;