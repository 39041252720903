import React, { useState, useContext, useEffect } from 'react';
import WarningAccountModal from '../modals/WarningAccountModal';
import { Link } from 'react-router-dom';
import SuspendDeleteAccountModal from '../modals/SuspendDeleteAccountModal';
import { LoggedUserContext } from '../../contexts/LoggedUser';
import history from '../../history';
const UserAccountDetails = (props) => {

    // showWarningAccountModal
    const [showWarningAccountModal, setShowWarningAccountModal] = useState(false);
    // showSuspendDeleteAccountModal
    const [showSuspendDeleteAccountModal, setShowSuspendDeleteAccountModal] = useState(false);
    // action
    // localStatus

    const { currentSelectedUser } = useContext(LoggedUserContext);
    const [actionI, setActionI] = useState(currentSelectedUser[2]);
    const [localStatus, setLocalStatus] = useState(currentSelectedUser[2]);

    useEffect(() => {
        if (currentSelectedUser === '') {
            history.push('/userAccounts');
        }
        console.log(currentSelectedUser);
    }, [])

    const showOrHideDeleteModal = () => {
        setShowWarningAccountModal(!showWarningAccountModal);
    }

    const showOrHideSuspendModal = () => {
        setShowSuspendDeleteAccountModal(!showSuspendDeleteAccountModal);
    }

    const continueToSuspendDeleteModal = () => {
        setShowWarningAccountModal(false);
        setShowSuspendDeleteAccountModal(true);
    }

    const changeStatus = () => {
        if (localStatus === 'active') {
            setLocalStatus('suspended')
        } else {
            setLocalStatus('active')
        }
    }

    return (
        <div>
            {currentSelectedUser === '' ? <div></div> :
                <div>
                    {showWarningAccountModal ? <WarningAccountModal action={actionI} showOrHideModal={showOrHideDeleteModal} continueToSuspendDeleteModal={continueToSuspendDeleteModal} connectedProviders={currentSelectedUser[6]} /> : <div></div>}

                    {showSuspendDeleteAccountModal ? <SuspendDeleteAccountModal showOrHideModal={showOrHideSuspendModal} action={actionI} changeStatus={changeStatus} /> : <div></div>}

                    {/* // <!-- Main Content--> */}
                    <div>
                        <ul className="container-with-sidebar__breadcrumbs sticky">
                            <li><a href="user-accounts.html">User Accounts</a></li>
                            <li>{currentSelectedUser[0]} {currentSelectedUser[1]}</li>
                            <li className="breadcrumbs--title">
                                <h1>Account Status</h1>
                            </li>
                        </ul>


                        {/* <!-- Contenido --> */}
                        <div className="container-with-sidebar__content-block">
                            <div className="row indented gap-triple">
                                <div className="col instructions">
                                    <p><img src="images/image-account-status.png" alt="Account Status" className="mb-2 mt-2" /></p>
                                </div>
                                <div className="col-grow-3">
                                    <a href="#" className={`btn ${localStatus === 'active' ? 'btn-active' : 'btn-suspended'} mr-2`}>{localStatus.toUpperCase()}</a>

                                    <p className="text-bold mb-2 mt-3">Usage</p>

                                    <div className="form-group mt-3">
                                        <label htmlFor="creation">Creation:</label>
                                        <input type="text" id="creation" readOnly value={currentSelectedUser[4]} />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="lstlogin">Last Login</label>
                                        <input type="text" id="lstlogin" readOnly value={currentSelectedUser[3]} />
                                    </div>

                                    <div className="form-group mt-3">
                                        <p className="text-bold mb-2 mt-3">Actions</p>
                                        <a
                                            onClick={() => {
                                                console.log(actionI);
                                                if (actionI === 'suspended') {
                                                    console.log('is active')
                                                    setActionI('active')
                                                } else {
                                                    console.log('is suspended')
                                                    setActionI('suspended')
                                                }
                                                setShowWarningAccountModal(!showWarningAccountModal)
                                            }
                                            }
                                            className="btn btn-secondary mr-2">{localStatus === 'active' ? 'Suspend Account' : 'Recover Account'}</a>
                                        <button type="button" className="btn btn-primary"
                                            onClick={() => {
                                                setActionI('deleted')
                                                setShowWarningAccountModal(!showWarningAccountModal)
                                            }
                                            }>Delete Account</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    );
}

export default UserAccountDetails;