import React, { createContext, useState } from 'react';

export const RegistrationContext = createContext();

const RegistrationContextProvider = (props) => {
    //email
    const [email, setEmail] = useState('');
    // profile
    const [profile, setProfile] = useState('');
    // password
    const [password, setPassword] = useState('');

    return (
        <RegistrationContext.Provider value={{ email, setEmail, profile, setProfile, password, setPassword }}>
            {props.children}
        </RegistrationContext.Provider>
    );
}

export default RegistrationContextProvider;